$base-font:'Montserrat', sans-serif;
$white: #fff;
$black: #000;
$base: #21201f;
$alt: #777777;
$foooterbgc: #111111;
$gray: #cccccc;
$green: #6e903c;

$thin: 100;
$light: 300;
$normal: 400;
$medium: 500;
$sbold: 600;
$bold: 700;
$xbold: 800;

$column: 100% / 12;